<template>
  <div class="email-rightbar mb-3">
    <div class="card">
      <div class="card-body">
        <h5 class="mb-3">Escrever mensagem</h5>
        <form @submit.prevent="onSubmit">
          <div class="form-group">
            <multiselect
              v-model="form.receiver"
              :options="options"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              placeholder="Escolha um usuário"
              track-by="id"
              :customLabel="(option) => `@${option.username}`"
              @search-change="searchUsers"
              @select="selectedTo"
            >
              <template slot="noOptions">Digite para ver opções</template>
            </multiselect>
          </div>

          <div class="form-group">
            <input
              type="text"
              class="form-control"
              placeholder="Assunto"
              v-model="form.subject"
              required
            />
          </div>
          <div class="form-group">
            <textarea
              cols="30"
              rows="10"
              class="form-control"
              placeholder="Escreva aqui sua mensagem..."
              v-model="form.body"
              required
            ></textarea>
          </div>

          <div class="mb-0">
            <div class="d-flex justify-content-between">
              <button type="submit" class="btn btn-success">
                <i class="mdi mdi-reply"></i>
                Enviar
              </button>
              <button @click.prevent="onReset()" class="btn btn-secondary">
                <i class="far fa-trash-alt"></i>
                Limpar dados
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapState } from "vuex";

import * as api from "@/api";

export default {
  components: { Multiselect },

  data() {
    return {
      userQuery: "",

      form: {
        receiver: false,
        subject: "",
        body: "",
      },

      options: [],
    };
  },

  computed: {
    ...mapState("auth", ["currentUser"]),
  },

  created() {
    if (Number.isFinite(+this.$route.query.receiverId)) {
      api
        .getUserById(this.$route.query.receiverId)
        .then((user) => {
          this.form.receiver = user;
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao encontrar usuário!", "error");
        });
    }
  },

  methods: {
    selectedTo(option) {
      this.userQuery = "";
      this.form.receiver = option;
    },

    searchUsers(search) {
      api.getUsers({ search }, true).then((users) => {
        this.options = users
          .filter((u) => u.id !== this.currentUser.id)
          .map((u) => ({
            ...u,
            label: u.username,
          }));
      });
    },

    onSubmit() {
      if (!this.form.body || !this.form.receiver || !this.form.subject) {
        return this.$swal(
          "Opa",
          "Por favor preencha todos os campos!",
          "warning"
        );
      }

      api
        .createConversation({
          receiver_id: this.form.receiver.id,
          subject: this.form.subject,
          body: this.form.body,
        })
        .then((conversation) => {
          this.$router.push({
            name: "viewConversation",
            params: {
              id: conversation.id,
            },
          });
          this.$swal("Sucesso", "Conversa criada", "success");
        })
        .catch(() => {
          this.$swal("Opa", "Falha ao criar conversa!", "error");
        });
    },

    onReset() {
      this.form.receiver = false;
      this.form.subject = "";
      this.form.body = "";
    },
  },
};
</script>
